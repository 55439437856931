const handed = [
    {
        _id: '1',
        name: 'Vasen',
        price: 0
    },
    {
        _id: '2',
        name: 'Oikea',
        price: 0
    },
    
]

export default handed