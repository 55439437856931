import React, {useState, useEffect, useContext } from 'react'
import {Form, Col, Button} from 'react-bootstrap'
import axios from 'axios'



import Loader from '../components/Loader'
import {AuthContext} from '../context/authContext'



const Products = () => {
    const auth = useContext(AuthContext);

    const [isLoading, setIsLoading] = useState(true)
    const [extras, setExtras] = useState([])
    const [installSurfaces, setInstallSurfaces] = useState([])
    const [installers, setInstallers] = useState([])
    const [mechanisms, setMechanisms] = useState([])
    const [fabrics, setFabrics] = useState([])
    const [lathColors, setLathColors] = useState([])
    const [brackets, setBrackets] = useState([])
    const [installLocations, setInstallLocations] = useState([])
    



    const [newFabric, setNewFabric] = useState({name: '', price: 0})
    const [newExtra, setNewExtra] = useState({name: '', price: 0})
    const [newInstallSurface, setNewInstallSurface] = useState({name: '', price: 0})
    const [newInstaller, setNewInstaller] = useState({name: 0, price: 0})
    const [newMechanism, setNewMechanism] = useState({name: '', price: 0, vaiKanLev: 0})
    const [newLathColor, setNewLathColor] = useState({name: '', price: 0})
    const [newBracket, setNewBracket] = useState({name: '', price: 0, vaiKanLev: 0})
    const [newInstallLocation, setNewInstallLocation] = useState({name: ''})

    const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }
    

    


    useEffect(() => {
        const fetchProducts = async () => {
                
            const fabricsData = await axios.get('/api/products/fabrics', config)
            const mechanismsData = await axios.get('/api/products/mechanisms', config)
            const extrasData = await axios.get('/api/products/extras', config)
            const installsurfacesData = await axios.get('/api/products/installsurfaces', config)
            const installersData = await axios.get('/api/products/installers', config)
            const lathColorsData = await axios.get('/api/products/lathColors', config)
            const bracketsData = await axios.get('/api/products/brackets', config)
            const installLocationsData = await axios.get('/api/products/inslocations', config)
                 
            setFabrics(fabricsData.data)
            setExtras(extrasData.data)
            setMechanisms(mechanismsData.data)
            setInstallSurfaces(installsurfacesData.data)
            setInstallers(installersData.data)
            setLathColors(lathColorsData.data)
            setBrackets(bracketsData.data)
            setInstallLocations(installLocationsData.data)
          
            setIsLoading(false);

            }
    
            fetchProducts()
            // eslint-disable-next-line
        }, [])


        // Handlers

        
        
        //Fab
        const handleNewFabric = async () => {
            const fabres = await axios.post('/api/products/fabrics',{ data: {fabricName: newFabric.name, fabricPrice: newFabric.price}}, config)
            let newfab = fabres.data
            let newfabs = [...fabrics]
            newfabs.push(newfab)
            setFabrics(newfabs)
        }

        const handleNewFabricChange = (event) => {
            const updatedFabric = {...newFabric}
            updatedFabric[event.target.name] = event.target.value
            setNewFabric(updatedFabric)
        }

        
        const handleDeleteFabric = async ( id ) => {
            //await axios.delete('/api/products/fabrics', { data: { removeId: id }}, config)
            await axios({
                url: `/api/products/fabrics/`,
                method:'delete',
                data : { removeId: id },
                headers:{Authorization: `Bearer ${auth.token}`}
              })
            let updatedFabrics = fabrics.filter(fabric => fabric._id !== id)
            setFabrics(updatedFabrics)
        }

        //Ext
        const handleNewExtra = async () => {
            const extres = await axios.post('/api/products/extras',{ data: {extraName: newExtra.name, extraPrice: newExtra.price}}, config)
            let newext = extres.data
            let newextrs = [...extras]
            newextrs.push(newext)
            setExtras(newextrs)
        }

        const handleNewExtraChange = (event) => {
            const updatedExtra = {...newExtra}
            updatedExtra[event.target.name] = event.target.value
            setNewExtra(updatedExtra)
        }

        
        const handleDeleteExtra = async ( id) => {
            await axios({
                url: `/api/products/extras/`,
                method:'delete',
                data : { removeId: id },
                headers:{Authorization: `Bearer ${auth.token}`}
              })
            let updatedExtras = extras.filter(fabric => fabric._id !== id)
            setExtras(updatedExtras)
        }
        
        //InsSur
        const handleNewInstallSurface = async () => {
            const inssurres = await axios.post('/api/products/installsurfaces',{ data: {installSurfaceName: newInstallSurface.name, installSurfacePrice: newInstallSurface.price}}, config)
            let newinssur = inssurres.data
            let newinssurs = [...installSurfaces]
            newinssurs.push(newinssur)
            setInstallSurfaces(newinssurs)
        }

        const handleNewInstallSurfaceChange = (event) => {
            const updatedInstallSurface = {...newInstallSurface}
            updatedInstallSurface[event.target.name] = event.target.value
            setNewInstallSurface(updatedInstallSurface)
        }

        
        const handleDeleteInstallSurface = async ( id) => {
            await axios({
                url: `/api/products/installsurfaces/`,
                method:'delete',
                data : { removeId: id },
                headers:{Authorization: `Bearer ${auth.token}`}
              })
            
            let updatedInstallSurfaces = installSurfaces.filter(installSurface => installSurface._id !== id)
            setInstallSurfaces(updatedInstallSurfaces)
        }
        
        //Mech
        const handleNewMechanism = async () => {
            const mechres = await axios.post('/api/products/mechanisms',{ data: {mechanismName: newMechanism.name, mechanismPrice: newMechanism.price, mechanismVaiKanLev: newMechanism.vaiKanLev}}, config)
            let newmecha = mechres.data
            let newmechas = [...mechanisms]
            newmechas.push(newmecha)
            setMechanisms(newmechas)
        }

        const handleNewMechanismChange = (event) => {
            const updatedMechanism = {...newMechanism}
            updatedMechanism[event.target.name] = event.target.value
            setNewMechanism(updatedMechanism)
        }

        
        const handleDeleteMechanism = async ( id) => {
            await axios({
                url: `/api/products/mechanisms/`,
                method:'delete',
                data : { removeId: id },
                headers:{Authorization: `Bearer ${auth.token}`}
              })
            
            let updatedMechanisms = mechanisms.filter(mechanism => mechanism._id !== id)
            setMechanisms(updatedMechanisms)
        }
        
        //Ins
        const handleNewInstaller = async () => {
            const insres = await axios.post('/api/products/installers',{ data: {installerName: newInstaller.name, installerPrice: newInstaller.price}}, config)
            let newins = insres.data
            let newinsrs = [...installers]
            newinsrs.push(newins)
            setInstallers(newinsrs)
        }

        const handleNewInstallerChange = (event) => {
            const updatedInstaller = {...newInstaller}
            updatedInstaller[event.target.name] = event.target.value
            setNewInstaller(updatedInstaller)
        }

        
        const handleDeleteInstaller = async ( id) => {
            await axios({
                url: `/api/products/installers/`,
                method:'delete',
                data : { removeId: id },
                headers:{Authorization: `Bearer ${auth.token}`}
              })
            
            let updatedInstallers = installers.filter(installer => installer._id !== id)
            setInstallers(updatedInstallers)
        }
        
        //LathCol
        const handleNewLathColor = async () => {
            const extres = await axios.post('/api/products/lathcolors',{ data: {lathColorName: newLathColor.name, lathColorPrice: newLathColor.price}}, config)
            let newlathC = extres.data
            let newlathCs = [...lathColors]
            newlathCs.push(newlathC)
            setLathColors(newlathCs)
        }

        const handleNewLathColorChange = (event) => {
            const updatedLathColor = {...newLathColor}
            updatedLathColor[event.target.name] = event.target.value
            setNewLathColor(updatedLathColor)
        }

        
        const handleDeleteLathColor = async ( id) => {
            await axios({
                url: `/api/products/lathcolors/`,
                method:'delete',
                data : { removeId: id },
                headers:{Authorization: `Bearer ${auth.token}`}
              })
            
            let updatedLathColors = lathColors.filter(lathColor => lathColor._id !== id)
            setLathColors(updatedLathColors)
        }

        // Ins Loc

        
        const handleNewInstallLocationChange = (event) => {
            const updatedInstallLocation = {...newInstallLocation}
            updatedInstallLocation[event.target.name] = event.target.value
            setNewInstallLocation(updatedInstallLocation)
        }

        //muokkaa
        const handleDeleteInstallLocation = async ( id) => {
            await axios({
                url: `/api/products/inslocations/`,
                method:'delete',
                data : { removeId: id },
                headers:{Authorization: `Bearer ${auth.token}`}
              })
            
            let updatedInstallLocations = installLocations.filter(installLocation => installLocation._id !== id)
            setInstallLocations(updatedInstallLocations)
        }
        const handleNewInstallLocation = async () => {
            const insLocres = await axios.post('/api/products/inslocations',{ data: {installLocationName: newInstallLocation.name}}, config)
            let newinsLoca = insLocres.data
            let newinsLocas = [...installLocations]
            newinsLocas.push(newinsLoca)
            setInstallLocations(newinsLocas)
        }




        //Brack
        
        const handleNewBracketChange = (event) => {
            const updatedBracket = {...newBracket}
            updatedBracket[event.target.name] = event.target.value
            setNewBracket(updatedBracket)
        }
        


        const handleNewBracket = async () => {
            const brackres = await axios.post('/api/products/brackets',{ data: {bracketName: newBracket.name, bracketPrice: newBracket.price, bracketVaiKanLev: newBracket.vaiKanLev}}, config)
            let newbracka = brackres.data
            let newbrackas = [...brackets]
            newbrackas.push(newbracka)
            setBrackets(newbrackas)
        }
     
        const handleDeleteBracket = async ( id) => {
            await axios({
                url: `/api/products/brackets/`,
                method:'delete',
                data : { removeId: id },
                headers:{Authorization: `Bearer ${auth.token}`}
              })
            
            let updatedBrackets = brackets.filter(bracket => bracket._id !== id)
            setBrackets(updatedBrackets)
        }
        
          
    
    //onChange={event =>handleInputChange(index, event)}

    return (

        <div>
            {isLoading ? <Loader/> :
            <React.Fragment>
            <Form.Group>
                <h2>Kankaat</h2>
                    <Form.Row className="mb-2">
                    <Form.Group as={Col} >
                    <Form.Label>Uusi kangas</Form.Label>
                    <Form.Control name="name" type="text" placeholder="Kangas" value={newFabric.name} onChange={event => handleNewFabricChange(event)} />
                    </Form.Group>
    
                    <Form.Group as={Col} >
                    <Form.Label>Hinta - €</Form.Label>
                    <Form.Control name="price" type="number" placeholder="Hinta" value={newFabric.price} onChange={event => handleNewFabricChange(event)} />
                    </Form.Group>
                    <Button variant="success" className="ml-1 mt-4 mb-3" onClick={() => handleNewFabric()}>Uusi</Button>
                    </Form.Row>
                {fabrics.map( (fabric, index) =>  
                    
                    <Form key={fabric._id}>
                    <Form.Row key={index}>
                    <Form.Group as={Col} >
                    <Form.Label>Kangas</Form.Label>
                    <Form.Control name="room" type="text" placeholder="Huone" value={fabric.name}  readOnly />
                    </Form.Group>
    
                    <Form.Group as={Col} >
                    <Form.Label>Hinta - €</Form.Label>
                    <Form.Control name="name" type="number" placeholder="Tunniste" value={fabric.price}  readOnly />
                    </Form.Group>
                    
                    
                    <Button variant="danger" className="ml-1 mt-4 mb-3" id={fabric._id} onClick={() => handleDeleteFabric(fabric._id)}>Poista</Button>
                    </Form.Row>
                    </Form>
                    )}
            </Form.Group>
            <Form.Group>
                <h2>Lisätarvikkeet</h2>
                    <Form.Row className="mb-2">
                    <Form.Group as={Col} >
                    <Form.Label>Uusi lisätarvike</Form.Label>
                    <Form.Control name="name" type="text" placeholder="Lisätarvike" value={newExtra.name} onChange={event => handleNewExtraChange(event)}/>
                    </Form.Group>
    
                    <Form.Group as={Col} >
                    <Form.Label>Hinta - €</Form.Label>
                    <Form.Control name="price" type="number" placeholder="Hinta" value={newExtra.price}  onChange={event => handleNewExtraChange(event)}/>
                    </Form.Group>
                    <Button variant="success" className="ml-1 mt-4 mb-3" onClick={() => handleNewExtra()}>Uusi</Button>
                    </Form.Row>
                {extras.map( (extra, index) =>  
                    <Form key={extra._id}>
                    <Form.Row key={index}>
                    <Form.Group as={Col} >
                    <Form.Label>Lisätarvike</Form.Label>
                    <Form.Control name="room" type="text" placeholder="Huone" value={extra.name}  readOnly />
                    </Form.Group>
    
                    <Form.Group as={Col} >
                    <Form.Label>Hinta - €</Form.Label>
                    <Form.Control name="name" type="number" placeholder="Tunniste" value={extra.price}  readOnly />
                    </Form.Group>
                    
                    
                    <Button variant="danger" className="ml-1 mt-4 mb-3" id={extra._id} onClick={() => handleDeleteExtra(extra._id)}>Poista</Button>
                    </Form.Row>
                    </Form>
                    )}
            </Form.Group>
            <Form.Group>
                <h2>Asennuspinnat</h2>
                    <Form.Row className="mb-2">
                    <Form.Group as={Col} >
                    <Form.Label>Uusi asennuspinta</Form.Label>
                    <Form.Control name="name" type="text" placeholder="Asennuspinta" value={newInstallSurface.name} onChange={event => handleNewInstallSurfaceChange(event)} />
                    </Form.Group>
    
                    <Form.Group as={Col} >
                    <Form.Label>Hinta - €</Form.Label>
                    <Form.Control name="price" type="number" placeholder="Hinta" value={newInstallSurface.price} onChange={event => handleNewInstallSurfaceChange(event)} />
                    </Form.Group>
                    <Button variant="success" className="ml-1 mt-4 mb-3" onClick={() => handleNewInstallSurface()}>Uusi</Button>
                    </Form.Row>
                {installSurfaces.map( (installSurface, index) =>  
                    <Form key={installSurface._id}>
                    <Form.Row key={index}>
                    <Form.Group as={Col} >
                    <Form.Label>Lisätarvike</Form.Label>
                    <Form.Control name="name" type="text" placeholder="Huone" value={installSurface.name}  readOnly />
                    </Form.Group>
    
                    <Form.Group as={Col} >
                    <Form.Label>Hinta - €</Form.Label>
                    <Form.Control name="price" type="number" placeholder="Tunniste" value={installSurface.price}  readOnly />
                    </Form.Group>
                    
                    
                    <Button variant="danger" className="ml-1 mt-4 mb-3" id={installSurface._id} onClick={() => handleDeleteInstallSurface(installSurface._id)}>Poista</Button>
                    </Form.Row>
                    </Form>
                    )}
            </Form.Group>
            <Form.Group>
                <h2>Asennuskohdat</h2>
                    <Form.Row className="mb-2">
                    <Form.Group as={Col} >
                    <Form.Label>Uusi asennuskohta</Form.Label>
                    <Form.Control name="name" type="text" placeholder="Asennuskohta" value={newInstallLocation.name} onChange={event => handleNewInstallLocationChange(event)} />
                    </Form.Group>
    
                    
                    <Button variant="success" className="ml-1 mt-4 mb-3" onClick={() => handleNewInstallLocation()}>Uusi</Button>
                    </Form.Row>
                {installLocations.map( (installLocation, index) =>  
                    <Form key={installLocation._id}>
                    <Form.Row key={index}>
                    <Form.Group as={Col} >
                    <Form.Label>Asennuskohta</Form.Label>
                    <Form.Control name="name" type="text" placeholder="Huone" value={installLocation.name}  readOnly />
                    </Form.Group>
    
                    
                    
                    
                    <Button variant="danger" className="ml-1 mt-4 mb-3" id={installLocation._id} onClick={() => handleDeleteInstallLocation(installLocation._id)}>Poista</Button>
                    </Form.Row>
                    </Form>
                    )}
            </Form.Group>
            <Form.Group>
                <h2>Mekanismit</h2>
                    <Form.Row className="mb-2">
                    <Form.Group as={Col} >
                    <Form.Label>Uusi mekanismi</Form.Label>
                    <Form.Control name="name" type="text" placeholder="Mekanismi"  value={newMechanism.name} onChange={event => handleNewMechanismChange(event)} />
                    </Form.Group>
    
                    <Form.Group as={Col} >
                    <Form.Label>Hinta - €</Form.Label>
                    <Form.Control name="price" type="number" placeholder="Hinta"  value={newMechanism.price} onChange={event => handleNewMechanismChange(event)} />
                    </Form.Group>
                    <Form.Group as={Col} >
                    <Form.Label>Vaikutus - cm</Form.Label>
                    <Form.Control name="vaiKanLev" type="number" placeholder="Vaikutus"  value={newMechanism.vaiKanLev} onChange={event => handleNewMechanismChange(event)} />
                    </Form.Group>
                    <Button variant="success" className="ml-1 mt-4 mb-3" onClick={() => handleNewMechanism()}>Uusi</Button>
                    </Form.Row>
                {mechanisms.map( (mechanism, index) =>  
                     <Form key={mechanism._id}>
                     <Form.Row key={index}>
                     <Form.Group as={Col} >
                     <Form.Label>Mekanismi</Form.Label>
                     <Form.Control name="name" type="text" placeholder="Huone" value={mechanism.name}  readOnly />
                     </Form.Group>
     
                     <Form.Group as={Col} >
                     <Form.Label>Hinta - €</Form.Label>
                     <Form.Control name="price" type="number" placeholder="Hinta" value={mechanism.price}  readOnly />
                     </Form.Group>
                     <Form.Group as={Col} >
                     <Form.Label>Vaikutus - cm</Form.Label>
                     <Form.Control name="price" type="number" placeholder="Vaikutus" value={mechanism.vaiKanLev}  readOnly />
                     </Form.Group>
                     
                     
                     <Button variant="danger" className="ml-1 mt-4 mb-3" id={mechanism._id} onClick={() => handleDeleteMechanism(mechanism._id)}>Poista</Button>
                     </Form.Row>
                     </Form>
                    )}
            </Form.Group>
            <Form.Group>
                <h2>Kannakkeet</h2>
                    <Form.Row className="mb-2">
                    <Form.Group as={Col} >
                    <Form.Label>Uusi kannake</Form.Label>
                    <Form.Control name="name" type="text" placeholder="Kannake"  value={newBracket.name} onChange={event => handleNewBracketChange(event)} />
                    </Form.Group>
    
                    <Form.Group as={Col} >
                    <Form.Label>Hinta - €</Form.Label>
                    <Form.Control name="price" type="number" placeholder="Hinta"  value={newBracket.price} onChange={event => handleNewBracketChange(event)} />
                    </Form.Group>
                    <Form.Group as={Col} >
                    <Form.Label>Vaikutus - cm</Form.Label>
                    <Form.Control name="vaiKanLev" type="number" placeholder="Vaikutus"  value={newBracket.vaiKanLev} onChange={event => handleNewBracketChange(event)} />
                    </Form.Group>
                    <Button variant="success" className="ml-1 mt-4 mb-3" onClick={() => handleNewBracket()}>Uusi</Button>
                    </Form.Row>
                {brackets.map( (bracket, index) =>  
                     <Form key={bracket._id}>
                     <Form.Row key={index}>
                     <Form.Group as={Col} >
                     <Form.Label>Kannake</Form.Label>
                     <Form.Control name="name" type="text" placeholder="Huone" value={bracket.name}  readOnly />
                     </Form.Group>
     
                     <Form.Group as={Col} >
                     <Form.Label>Hinta - €</Form.Label>
                     <Form.Control name="price" type="number" placeholder="Tunniste" value={bracket.price}  readOnly />
                     </Form.Group>

                     <Form.Group as={Col} >
                     <Form.Label>Vaikutus - cm</Form.Label>
                     <Form.Control name="price" type="number" placeholder="Tunniste" value={bracket.vaiKanLev}  readOnly />
                     </Form.Group>
                     
                     
                     <Button variant="danger" className="ml-1 mt-4 mb-3" id={bracket._id} onClick={() => handleDeleteBracket(bracket._id)}>Poista</Button>
                     </Form.Row>
                     </Form>
                    )}
            </Form.Group>
            <Form.Group>
                <h2>Alalistat</h2>
                    <Form.Row className="mb-2">
                    <Form.Group as={Col} >
                    <Form.Label>Uusi alalista</Form.Label>
                    <Form.Control name="name" type="text" placeholder="Alalista" value={newLathColor.name} onChange={event => handleNewLathColorChange(event)} />
                    </Form.Group>
    
                    <Form.Group as={Col} >
                    <Form.Label>Hinta - €</Form.Label>
                    <Form.Control name="price" type="number" placeholder="Hinta" value={newLathColor.price} onChange={event => handleNewLathColorChange(event)} />
                    </Form.Group>
                    <Button variant="success" className="ml-1 mt-4 mb-3" onClick={() => handleNewLathColor()}>Uusi</Button>
                    </Form.Row>
                {lathColors.map( (lathColor, index) =>  
                    <Form key={lathColor._id}>
                    <Form.Row key={index}>
                    <Form.Group as={Col} >
                    <Form.Label>Alalista</Form.Label>
                    <Form.Control name="name" type="text" placeholder="Huone" value={lathColor.name}  readOnly />
                    </Form.Group>
    
                    <Form.Group as={Col} >
                    <Form.Label>Hinta - €</Form.Label>
                    <Form.Control name="price" type="number" placeholder="Tunniste" value={lathColor.price}  readOnly />
                    </Form.Group>
                    
                    
                    <Button variant="danger" className="ml-1 mt-4 mb-3" id={lathColor._id} onClick={() => handleDeleteLathColor(lathColor._id)}>Poista</Button>
                    </Form.Row>
                    </Form>
                    )}
            </Form.Group>
            <Form.Group>
                <h2>Asentajat</h2>
                    <Form.Row className="mb-2">
                    <Form.Group as={Col} >
                    <Form.Label>Uusi asentajamäärä</Form.Label>
                    <Form.Control name="name" type="number" placeholder="Asentaja" value={newInstaller.name} onChange={event => handleNewInstallerChange(event)} />
                    </Form.Group>
    
                    <Form.Group as={Col} >
                    <Form.Label>Hinta - €/tunti</Form.Label>
                    <Form.Control name="price" type="number" placeholder="Hinta" value={newInstaller.price} onChange={event => handleNewInstallerChange(event)} />
                    </Form.Group>
                    <Button variant="success" className="ml-1 mt-4 mb-3" onClick={() => handleNewInstaller()}>Uusi</Button>
                    </Form.Row>
                {installers.map( (installer, index) =>  
                    <Form key={installer._id}>
                    <Form.Row key={index}>
                    <Form.Group as={Col} >
                    <Form.Label>Asentajamäärä</Form.Label>
                    <Form.Control name="name" type="text" placeholder="Huone" value={installer.name}  readOnly />
                    </Form.Group>
    
                    <Form.Group as={Col} >
                    <Form.Label>Hinta - €/tunti</Form.Label>
                    <Form.Control name="price" type="number" placeholder="Tunniste" value={installer.price}  readOnly />
                    </Form.Group>
                    
                    
                    <Button variant="danger" className="ml-1 mt-4 mb-3" id={installer._id} onClick={() => handleDeleteInstaller(installer._id)}>Poista</Button>
                    </Form.Row>
                    </Form>
                    )}
            </Form.Group>
            </React.Fragment>
            
                   
                }
            
        </div>
    )
}

export default Products
