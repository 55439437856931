import React, { useState, useCallback, useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom'
import Header from './components/Header'
import Footer from './components/Footer'
import './App.css';
import NewOrder from './screens/NewOrder';
import Products from './screens/Products';
import Login from './screens/Login'
import AllOrders from './screens/AllOrders'

import SingleOrder from './screens/SingleOrder'
import {AuthContext} from './context/authContext'
import UserEdit from './screens/UserEdit'
import SecureStorage from 'secure-web-storage'


let autoLogoutTimer;

const App = () => {
  

  const [token, setToken] = useState(false);
  const [tokenExpirationTime, setTokenExpirationTime] = useState();
  const [userId, setUserId] = useState(false);
  const [userName, setUserName] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  var CryptoJS = require("crypto-js");
 
  var SECRET_KEY = 'my secret key';
  
  var secureStorage = new SecureStorage(sessionStorage, {
      hash: function hash(key) {
          key = CryptoJS.SHA256(key, SECRET_KEY);
  
          return key.toString();
      },
      encrypt: function encrypt(data) {
          data = CryptoJS.AES.encrypt(data, SECRET_KEY);
  
          data = data.toString();
  
          return data;
      },
      decrypt: function decrypt(data) {
          data = CryptoJS.AES.decrypt(data, SECRET_KEY);
  
          data = data.toString(CryptoJS.enc.Utf8);
  
          return data;
      }
  });





  const login = useCallback((uid, token, uName, isAdmn, expirationTime) => {
    setToken(token);
    setUserId(uid);
    setUserName(uName);
    setIsAdmin(isAdmn);
    const expireToken =
      expirationTime || new Date(new Date().getTime() + 50400000);
    setTokenExpirationTime(expireToken);
    secureStorage.setItem(
      'userData',
      JSON.stringify({
        userId: uid,
        token: token,
        expiration: expireToken.toISOString(),
        isAdmin: isAdmn,
        userName: uName
      })
    );
    // eslint-disable-next-line
  }, []);
  const logout = useCallback(() => {
    setToken(null);
    setUserId(null);
    setUserName(null);
    setUserId(null);
    setTokenExpirationTime(null);
    secureStorage.removeItem('userData');
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (token && tokenExpirationTime) {
      const timeRemaining =
        tokenExpirationTime.getTime() - new Date().getTime();
      autoLogoutTimer = setTimeout(logout, timeRemaining);
    } else {
      clearTimeout(autoLogoutTimer);
    }
  }, [token, logout, tokenExpirationTime]);

  useEffect(() => {
    const storedData = JSON.parse(secureStorage.getItem('userData'));
    if (
      storedData &&
      storedData.token &&
      new Date(storedData.expiration) > new Date()
    ) {
      login(storedData, storedData.token, storedData.userName, storedData.isAdmin, new Date(storedData.expiration));
    }
    // eslint-disable-next-line
  }, [login ]);

  let validRoutes;

  if (token && !isAdmin) {
    validRoutes = (
      <Switch>
        <Route path="/" component={Login} exact/>
          <Route path="/order" component={NewOrder} exact/>
          
          <Route path="/orders" component={AllOrders} exact/>
          <Route path="/orders/:orderid" component={SingleOrder} exact/>
        <Redirect to='/' />
      </Switch>
    );
  } else if(token && isAdmin) {
    validRoutes = (
      <Switch>
        <Route path="/" component={Login} exact/>
          <Route path="/order" component={NewOrder} exact/>
          <Route path="/products" component={Products} exact/>
          <Route path="/orders" component={AllOrders} exact/>
          <Route path="/orders/:orderid" component={SingleOrder} exact/>
          <Route path="/users" component={UserEdit} exact/>
        <Redirect to='/' />
      </Switch>
    );
  } else {
    validRoutes = (
      
      <Switch>
        <Route path="/" component={Login} exact/>
        <Redirect to='/' />
      </Switch>
      )
  }


  return (
    <AuthContext.Provider
      value={{
        isAdmin: isAdmin,
        token: token,
        userId: userId,
        userName: userName,
        login: login,
        logout: logout,
      }}
    >
    <BrowserRouter>
    
      <Header />
      <main className="py-3">
        <Container>
          
          {validRoutes}
      
        </Container>
      </main>
      <Footer />
    
    </BrowserRouter>
    </AuthContext.Provider>
  );
}

export default App;
