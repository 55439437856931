import React, {useState, useEffect, useContext} from 'react'
import {Form, Col, Button} from 'react-bootstrap'
import handed from '../katisyys'
import rewinding from '../kelautuvuus'
import mittaustavat from '../mittaustavat'

import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import Loader from '../components/Loader'
import {AuthContext} from '../context/authContext'
import { useHistory } from 'react-router-dom';

const NewOrder = () => {
    const auth = useContext(AuthContext);
    const history = useHistory()

    const neliohinta = (kang, pit, lev) => {
        let nelio = (pit * lev) / 10000
        return Number(nelio * kang)
    }
    
    const overallPriceCalculator = (ikku) => {
        let pituus = ikku.length
        let kokonais = 0
        for(let i=0; i < pituus; i++){
            kokonais = kokonais + Number(ikku[i].windowprice)
        }
        return kokonais
    }

    const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }

    

    
    const [orderLaborAmount, setOrderLaborAmount] = useState(0);
    const [orderTime, setOrderTime] = useState('');
    const [installers, setInstallers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const[varmistusPainike, setVarmistusPainike] = useState(false);
    const[orderSaveSuccess, setOrderSaveSuccess] = useState(false);
    
    const[notEnteredField, setNotEnteredField] = useState([]);

    

    const [windowPrice, setWindowPrice] = useState([
        {   
            width: 0,
            height: 0, 
            installSurface: 0,
            mechanism: 0,
            fabrics: 0,
            lathColor: 0,
            extras: 0,
            brackets: 0,
            measurement: 0,
            mechanismLen: 0,
            bracketsLen: 0
        }
    ])
    const [orderName, setOrderName] = useState('')
    const [windows, setWindows] = useState([
        {   room: '',
            identifier: '',
            width: '',
            height: '',
            installHeight: '',
            installSurface: [],
            measurement: mittaustavat,
            brackets: [],
            installLocations: [],
            mechanism: [],
            hand: handed,
            rewind: rewinding,
            fabrics: [],
            lathColor: [],
            extras: [],
            windowprice: 0,
            freeComment: '',
            chainLength: '',
            windowAmount: 1
        }
    ])
    const [order, setOrder] = useState([
        {   room: '',
            identifier: '',
            width: 0,
            height: 0,
            installHeight: 0,
            installSurface: '',
            measurement: '',
            brackets: '',
            installLocations: '',
            mechanism: '',
            hand: '',
            rewind: '',
            fabrics: '',
            lathColor: '',
            extras: [],
            windowprice: 0,
            freeComment:'',
            fabricWidth: 0,
            pipeLength: 0,
            chainLength: 0
        }
    ])
    const [orderWithCopies, setOrderWithCopies] = useState([
        {   room: '',
            identifier: '',
            width: 0,
            height: 0,
            installHeight: 0,
            installSurface: '',
            measurement: '',
            brackets: '',
            installLocations: '',
            mechanism: '',
            hand: '',
            rewind: '',
            fabrics: '',
            lathColor: '',
            extras: [],
            windowprice: 0,
            freeComment:'',
            fabricWidth: 0,
            pipeLength: 0,
            chainLength: 0
        }
    ])

    const [overallPrice, setoverallPrice] = useState(0)

    const [overallPriceAll, setOverallPriceAll] = useState(0)

    const [overallPriceWithCopies, setoverallPriceWithCopies] = useState(0)

    const [overallPriceAllWithCopies, setOverallPriceAllWithCopies] = useState(0)


    





    //FETCH DATA


    useEffect(() => {
        const fetchProducts = async () => {
            
            const preValues = [...windows] 
            const fabricsData = await axios.get('/api/products/fabrics', config)
            const mechanismsData = await axios.get('/api/products/mechanisms', config)
            const extrasData = await axios.get('/api/products/extras', config)
            const installsurfacesData = await axios.get('/api/products/installsurfaces', config)
            const installersData = await axios.get('/api/products/installers', config)
            const lathColorsData = await axios.get('/api/products/lathColors', config)
            const bracketsData = await axios.get('/api/products/brackets', config)
            const installLocationsData = await axios.get('/api/products/inslocations', config)
            
            preValues[0].fabrics = fabricsData.data;
            preValues[0].extras = extrasData.data;
            preValues[0].mechanism = mechanismsData.data;
            preValues[0].installSurface = installsurfacesData.data;
            preValues[0].lathColor = lathColorsData.data;
            preValues[0].brackets = bracketsData.data;
            preValues[0].installLocations = installLocationsData.data;
            
            setWindows(preValues)
            setInstallers(installersData.data)
            
            setIsLoading(false)  
        }

        fetchProducts()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    



    



    const handleVarmistus = (event) => { 
        const values = [...windows]    
        const orderValues = [...order]  
        

        for(let j = 0; j < windows.length; j++){
            orderValues[j].windowprice = values[j].windowprice
        }
        setOrder(orderValues)
        
        
        let notEntered = []

        if(orderName.length === 0){
            notEntered.push(`Tilauksen nimi, `)
        }
        if(!installers.installers){
            notEntered.push(`Asentajat, `)
        }
        if(orderTime === ''){
            notEntered.push(`Asennusaika, `)
        }
        
        
        for(let i=0; i< order.length; i++){
            
            if(order[i].room.length === 0){
                notEntered.push(`Ikkuna ${i+1} nimi, `)
            }
            if(order[i].identifier.length === 0){
                notEntered.push(`Ikkuna ${i+1} tunniste, `)
            }
            if(order[i].width === 0){
                notEntered.push(`Ikkuna ${i+1} leveys, `)
            }
            if(order[i].height === 0){
                notEntered.push(`Ikkuna ${i+1} korkeus, `)
            }
            if(order[i].installHeight === 0){
                notEntered.push(`Ikkuna ${i+1} asennuskorkeus, `)
            }
            if(order[i].installSurface.length === 0){
                notEntered.push(`Ikkuna ${i+1} asennuspinta, `)
            }
            if(order[i].mechanism.length === 0){
                notEntered.push(`Ikkuna ${i+1} mekanismi, `)
            }
            if(order[i].hand.length === 0){
                notEntered.push(`Ikkuna ${i+1} kätisyys, `)
            }
            if(order[i].rewind.length === 0){
                notEntered.push(`Ikkuna ${i+1} kelautuvuus, `)
            }
            if(order[i].fabrics.length === 0){
                notEntered.push(`Ikkuna ${i+1} kangas, `)
            }
            if(order[i].lathColor.length === 0){
                notEntered.push(`Ikkuna ${i+1} alalista, `)
            }
            if(order[i].brackets.length === 0){
                notEntered.push(`Ikkuna ${i+1} kannake, `)
            }
            if(order[i].installLocations.length === 0){
                notEntered.push(`Ikkuna ${i+1} asennuskohta, `)
            }
            if(order[i].measurement.length === 0){
                notEntered.push(`Ikkuna ${i+1} mittaustapa, `)
            }
            if(order[i].chainLength === 0){
                notEntered.push(`Ikkuna ${i+1} ketjun pituus, `)
            }
            
            
        }

        
        
        setNotEnteredField(notEntered)
        if(notEntered.length === 0 && overallPriceAll > 0) {
            setVarmistusPainike(event.target.checked)
            setOverallPriceAll(orderLaborAmount + overallPrice)

            let finalOrder = []
            let finalPrice = 0
        
            for(let k=0; k < orderValues.length; k++){
            for(let l = 0; l < Number(values[k].windowAmount); l++){
                finalOrder.push(orderValues[k])
                finalPrice = finalPrice + orderValues[k].windowprice
                }
            }
            setOrderWithCopies(finalOrder);
            setoverallPriceWithCopies(finalPrice);
            setOverallPriceAllWithCopies(finalPrice + orderLaborAmount);
    

        }

    }




    const handleNewWindow = () => {
        
        
        const newLisatarvike = windows[0].extras.map( lisatarvike => {
            return {
            _id: lisatarvike._id,
            name: lisatarvike.name,
            price: lisatarvike.price,
            isSelected: false}
        })
        
        setWindows([...windows, {   room: '',
        identifier: '',
        width: '',
        height: '',
        installHeight: '',
        installSurface: windows[0].installSurface,
        measurement: mittaustavat,
        mechanism: windows[0].mechanism,
        hand: handed,
        rewind: rewinding,
        fabrics: windows[0].fabrics,
        lathColor: windows[0].lathColor,
        extras: newLisatarvike,
        windowprice: 0,
        brackets: windows[0].brackets,
        installLocations: windows[0].installLocations,
        freeComment:'',
        chainLength: '',
        windowAmount: 1
        }])
        setOrder([...order, 
            {   room: '',
            identifier: '',
            width: 0,
            height: 0,
            installHeight: 0,
            installSurface: '',
            mechanism: '',
            hand: '',
            rewind: '',
            fabrics: '',
            lathColor: '',
            extras: [],
            windowprice: 0,
            measurement: '',
            brackets: '',
            installLocations: '',
            freeComment:'',
            fabricWidth: 0,
            chainLength: 0,
            pipeLength: 0
        }])
        setWindowPrice([...windowPrice,
            {   
                width: 0,
                height: 0, 
                installSurface: 0,
                mechanism: 0,
                fabrics: 0,
                lathColor: 0,
                extras: 0,
                brackets: 0,
                measurement: 0,
                mechanismLen: 0,
                bracketsLen: 0

            }
        ])

        
        setoverallPrice(overallPriceCalculator(windows))
        setOverallPriceAll(orderLaborAmount + overallPriceCalculator(windows))
        
        setVarmistusPainike(false)
    }

    const handleInputChange = (index, event) => {
        const values = [...windows]    
        const prices = [...windowPrice]
        let orderValues = [...order]  

        if(event.target.name === 'width' || event.target.name === 'height'){
            
            prices[index][event.target.name] = event.target.value;
            setWindowPrice(prices)
            setVarmistusPainike(false)

        }
        
        
        values[index][event.target.name] = event.target.value;

        if(order[index].measurement === 'Kankaan leveys'){
            values[index].windowprice = (neliohinta(Number(windowPrice[index].fabrics),Number(windowPrice[index].width),Number(windowPrice[index].height)) + windowPrice[index].extras + Number(windowPrice[index].installSurface) + Number(windowPrice[index].lathColor) + Number(windowPrice[index].mechanism) + Number(windowPrice[index].brackets))
            
            orderValues[index].fabricWidth = Number(windowPrice[index].width)
            orderValues[index].pipeLength = Number(windowPrice[index].width) + 1
        } else {
            values[index].windowprice = (neliohinta(Number(windowPrice[index].fabrics),(Number(windowPrice[index].width) - 1 + Number(windowPrice[index].mechanismLen) + Number(windowPrice[index].bracketsLen)),Number(windowPrice[index].height)) + windowPrice[index].extras + Number(windowPrice[index].installSurface) + Number(windowPrice[index].lathColor) + Number(windowPrice[index].mechanism) + Number(windowPrice[index].brackets))

            let adjustedlen = (Number(windowPrice[index].width) + Number(windowPrice[index].mechanismLen) + Number(windowPrice[index].bracketsLen))
            orderValues[index].fabricWidth = adjustedlen - 1
            orderValues[index].pipeLength = adjustedlen
        }
        setWindows(values)
        
        orderValues[index][event.target.name] = event.target.value;
   

        if(order[index].measurement === 'Kankaan leveys'){
            orderValues[index].windowprice = (neliohinta(Number(windowPrice[index].fabrics),Number(windowPrice[index].width),Number(windowPrice[index].height)) + windowPrice[index].extras + Number(windowPrice[index].installSurface) + Number(windowPrice[index].lathColor) + Number(windowPrice[index].mechanism) + Number(windowPrice[index].brackets))
            
            orderValues[index].fabricWidth = Number(windowPrice[index].width)
            orderValues[index].pipeLength = Number(windowPrice[index].width) + 1
        } else {
            orderValues[index].windowprice = (neliohinta(Number(windowPrice[index].fabrics),(Number(windowPrice[index].width) - 1 + Number(windowPrice[index].mechanismLen) + Number(windowPrice[index].bracketsLen)),Number(windowPrice[index].height)) + windowPrice[index].extras + Number(windowPrice[index].installSurface) + Number(windowPrice[index].lathColor) + Number(windowPrice[index].mechanism) + Number(windowPrice[index].brackets))

            let adjustedlen = (Number(windowPrice[index].width) + Number(windowPrice[index].mechanismLen) + Number(windowPrice[index].bracketsLen))
            orderValues[index].fabricWidth = adjustedlen - 1
            orderValues[index].pipeLength = adjustedlen
        }
        setOrder(orderValues)
        setoverallPrice(overallPriceCalculator(windows))
        setOverallPriceAll(orderLaborAmount + overallPriceCalculator(windows))
        
        setVarmistusPainike(false)

    
        
        
    }
    const handleInputChangeSelect = (index, event, i) => {  
        
        setVarmistusPainike(false)
        let newArr = event.target.value.split(',')
        const prices = [...windowPrice] 
        const values = [...windows]  
        let orderValues = [...order]

        if(event.target.name === 'mechanism'){
            
            prices[index].mechanismLen = newArr[2]
        }
        if(event.target.name === 'brackets'){
            
            prices[index].bracketsLen = newArr[2]
        }


        

        if(event.target.name === 'extras'){

            values[index].extras[i].isSelected = event.target.checked
            setWindows(values)
            setoverallPrice(overallPriceCalculator(windows))
            setOverallPriceAll(orderLaborAmount + overallPriceCalculator(windows))
            
 
            if(event.target.checked){
                orderValues[index].extras.push(newArr[0])

                

                if(order[index].measurement === 'Kankaan leveys'){
                    orderValues[index].windowprice = (neliohinta(Number(windowPrice[index].fabrics),Number(windowPrice[index].width),Number(windowPrice[index].height)) + windowPrice[index].extras + Number(windowPrice[index].installSurface) + Number(windowPrice[index].lathColor) + Number(windowPrice[index].mechanism) + Number(windowPrice[index].brackets))
                    
                    orderValues[index].fabricWidth = Number(windowPrice[index].width)
                    orderValues[index].pipeLength = Number(windowPrice[index].width) + 1
                } else {
                    orderValues[index].windowprice = (neliohinta(Number(windowPrice[index].fabrics),(Number(windowPrice[index].width) - 1 + Number(windowPrice[index].mechanismLen) + Number(windowPrice[index].bracketsLen)),Number(windowPrice[index].height)) + windowPrice[index].extras + Number(windowPrice[index].installSurface) + Number(windowPrice[index].lathColor) + Number(windowPrice[index].mechanism) + Number(windowPrice[index].brackets))
        
                    let adjustedlen = (Number(windowPrice[index].width) + Number(windowPrice[index].mechanismLen) + Number(windowPrice[index].bracketsLen))
                    orderValues[index].fabricWidth = adjustedlen - 1
                    orderValues[index].pipeLength = adjustedlen
                }

                setOrder(orderValues)

                prices[index][event.target.name] = Number(prices[index][event.target.name]) + Number(newArr[1]);

                setWindowPrice(prices)

                

                

                if(order[index].measurement === 'Kankaan leveys'){
                    values[index].windowprice = (neliohinta(Number(windowPrice[index].fabrics),Number(windowPrice[index].width),Number(windowPrice[index].height)) + windowPrice[index].extras + Number(windowPrice[index].installSurface) + Number(windowPrice[index].lathColor) + Number(windowPrice[index].mechanism) + Number(windowPrice[index].brackets))
                    
                    orderValues[index].fabricWidth = Number(windowPrice[index].width)
                    orderValues[index].pipeLength = Number(windowPrice[index].width) + 1
                } else {
                    values[index].windowprice = (neliohinta(Number(windowPrice[index].fabrics),(Number(windowPrice[index].width) - 1 + Number(windowPrice[index].mechanismLen) + Number(windowPrice[index].bracketsLen)),Number(windowPrice[index].height)) + windowPrice[index].extras + Number(windowPrice[index].installSurface) + Number(windowPrice[index].lathColor) + Number(windowPrice[index].mechanism) + Number(windowPrice[index].brackets))
        
                    let adjustedlen = (Number(windowPrice[index].width) + Number(windowPrice[index].mechanismLen) + Number(windowPrice[index].bracketsLen))
                    orderValues[index].fabricWidth = adjustedlen - 1
                    orderValues[index].pipeLength = adjustedlen
                }
                setWindows(values)
                setoverallPrice(overallPriceCalculator(windows))
                setOverallPriceAll(orderLaborAmount + overallPriceCalculator(windows))
                
                
                
            } else if(!event.target.checked){  
                let arr = orderValues[index].extras
                arr = orderValues[index].extras.filter(lisa => lisa !== newArr[0])
                orderValues[index].extras = arr
                

                if(order[index].measurement === 'Kankaan leveys'){
                    orderValues[index].windowprice = (neliohinta(Number(windowPrice[index].fabrics),Number(windowPrice[index].width),Number(windowPrice[index].height)) + windowPrice[index].extras + Number(windowPrice[index].installSurface) + Number(windowPrice[index].lathColor) + Number(windowPrice[index].mechanism) + Number(windowPrice[index].brackets))
                    
                    orderValues[index].fabricWidth = Number(windowPrice[index].width)
                    orderValues[index].pipeLength = Number(windowPrice[index].width) + 1
                } else {
                    orderValues[index].windowprice = (neliohinta(Number(windowPrice[index].fabrics),(Number(windowPrice[index].width) - 1 + Number(windowPrice[index].mechanismLen) + Number(windowPrice[index].bracketsLen)),Number(windowPrice[index].height)) + windowPrice[index].extras + Number(windowPrice[index].installSurface) + Number(windowPrice[index].lathColor) + Number(windowPrice[index].mechanism) + Number(windowPrice[index].brackets))
        
                    let adjustedlen = (Number(windowPrice[index].width) + Number(windowPrice[index].mechanismLen) + Number(windowPrice[index].bracketsLen))
                    orderValues[index].fabricWidth = adjustedlen - 1
                    orderValues[index].pipeLength = adjustedlen
                }
                setOrder(orderValues)
                prices[index][event.target.name] = Number(prices[index][event.target.name]) - Number(newArr[1]);
                setWindowPrice(prices)
                
                

                if(order[index].measurement === 'Kankaan leveys'){
                    values[index].windowprice = (neliohinta(Number(windowPrice[index].fabrics),Number(windowPrice[index].width),Number(windowPrice[index].height)) + windowPrice[index].extras + Number(windowPrice[index].installSurface) + Number(windowPrice[index].lathColor) + Number(windowPrice[index].mechanism) + Number(windowPrice[index].brackets))
                    
                    orderValues[index].fabricWidth = Number(windowPrice[index].width)
                    orderValues[index].pipeLength = Number(windowPrice[index].width) + 1
                } else {
                    values[index].windowprice = (neliohinta(Number(windowPrice[index].fabrics),(Number(windowPrice[index].width) - 1 + Number(windowPrice[index].mechanismLen) + Number(windowPrice[index].bracketsLen)),Number(windowPrice[index].height)) + windowPrice[index].extras + Number(windowPrice[index].installSurface) + Number(windowPrice[index].lathColor) + Number(windowPrice[index].mechanism) + Number(windowPrice[index].brackets))
        
                    let adjustedlen = (Number(windowPrice[index].width) + Number(windowPrice[index].mechanismLen) + Number(windowPrice[index].bracketsLen))
                    orderValues[index].fabricWidth = adjustedlen - 1
                    orderValues[index].pipeLength = adjustedlen
                }
                setWindows(values)
                setoverallPrice(overallPriceCalculator(windows))
                setOverallPriceAll(orderLaborAmount + overallPriceCalculator(windows))
                
                
            }
    
        } else {
        
        
        prices[index][event.target.name] = newArr[1];
        orderValues[index][event.target.name] = newArr[0];
        setOrder(orderValues)
        setWindowPrice(prices)
        

        if(order[index].measurement === 'Kankaan leveys'){
            values[index].windowprice = (neliohinta(Number(windowPrice[index].fabrics),Number(windowPrice[index].width),Number(windowPrice[index].height)) + windowPrice[index].extras + Number(windowPrice[index].installSurface) + Number(windowPrice[index].lathColor) + Number(windowPrice[index].mechanism) + Number(windowPrice[index].brackets))
            
            orderValues[index].fabricWidth = Number(windowPrice[index].width)
            orderValues[index].pipeLength = Number(windowPrice[index].width) + 1
        } else {
            values[index].windowprice = (neliohinta(Number(windowPrice[index].fabrics),(Number(windowPrice[index].width) - 1 + Number(windowPrice[index].mechanismLen) + Number(windowPrice[index].bracketsLen)),Number(windowPrice[index].height)) + windowPrice[index].extras + Number(windowPrice[index].installSurface) + Number(windowPrice[index].lathColor) + Number(windowPrice[index].mechanism) + Number(windowPrice[index].brackets))

            let adjustedlen = (Number(windowPrice[index].width) + Number(windowPrice[index].mechanismLen) + Number(windowPrice[index].bracketsLen))
            orderValues[index].fabricWidth = adjustedlen - 1
            orderValues[index].pipeLength = adjustedlen
        }
        setWindows(values)
        setoverallPrice(overallPriceCalculator(windows))
        setOverallPriceAll(orderLaborAmount + overallPriceCalculator(windows))
        
   
        }
   
        
    }

    const handleOrderName = (event) => {         
        setOrderName(event.target.value) 
        setVarmistusPainike(false)
        
    }
    



    const handleOrderSend = async () => {

        let insValue = installers.installers.split(',')



        
        const fabres = await axios.post('/api/orders/neworder',{ data: {orderName: orderName, sellerName: auth.userName, orderPrice: overallPriceWithCopies, orderPriceAll: overallPriceAllWithCopies, orderPriceLabor: orderLaborAmount, installers: Number(insValue[0]), orderTime: Number(orderTime), sellerId:auth.userId, windows: orderWithCopies}}, config)
        if(fabres.data.createdOrder.orderName === orderName && fabres.statusText === "OK"){
            setOrderSaveSuccess(true)
        }
         else {
            console.log('Operaatiovirhe')
        }
        
        
    }


     const handleDeleteWindow = (indx) => {
        
        const delPrices = [...windowPrice] 
        const deleteArr1 = [...windows]
        const deleteArr2 = [...order] 
        const deletedWindow1 = windows[indx]
        deleteArr1.splice(indx, 1);
        deleteArr2.splice(indx, 1);
        delPrices.splice(indx, 1);
        setWindows(deleteArr1)
        setOrder(deleteArr2)
        setWindowPrice(delPrices)
        setoverallPrice(overallPrice - deletedWindow1.windowprice)
        setVarmistusPainike(false)
        setOverallPriceAll(orderLaborAmount + overallPrice - deletedWindow1.windowprice)
        setOverallPriceAll(orderLaborAmount + overallPrice)

    }

    const handleInputChangeTime = ( event ) => {
        setOrderTime(event.target.value) 
        setVarmistusPainike(false)

        if(installers.installers){
            let newArr = installers.installers.split(',')

            let laborPrice = Number(newArr[1]) * Number(event.target.value)
            setOrderLaborAmount(laborPrice)
            setOverallPriceAll(laborPrice + overallPrice)
        }
        
    }
    const handleInputChangeSelectLabor = (event, index) => {
        setVarmistusPainike(false)
        const values = [...installers]    
        

        values[event.target.name] = event.target.value;

        setInstallers(values)
        let newArr = event.target.value.split(',')

        let laborPrice = Number(newArr[1]) * Number(orderTime)
        setOrderLaborAmount(laborPrice)

        setOverallPriceAll(laborPrice + overallPrice)
    }
    




    return (
        <React.Fragment>
            {isLoading ? <Loader />:
            <Form>
                <Form.Group as={Col} controlId="Tilaus">
                <Form.Label>Tilaaja</Form.Label>
                <Form.Control name="tilaaja "type="text" placeholder="Tilaaja" value={orderName} onChange={event =>handleOrderName(event)} />
                </Form.Group>

            {windows.map((window, index) => (
                <Form.Group key={index}>
               <Form.Group >
                   <Form.Row className="mt-5 mb-3">
                       <h4>Ikkuna {index + 1}</h4>
                   </Form.Row>
                    <Form.Row>
                   <Form.Group as={Col} >
                   <Form.Label>Huone</Form.Label>
                   <Form.Control name="room" type="text" placeholder="Huone" value={windows[index].room} onChange={event =>handleInputChange(index, event)}/>
                   </Form.Group>
   
                   <Form.Group as={Col} >
                   <Form.Label>Tunniste</Form.Label>
                   <Form.Control name="identifier" type="text" placeholder="Tunniste" value={windows[index].identifier} onChange={event =>handleInputChange(index, event)}/>
                   </Form.Group>
               </Form.Row>
               <Form.Row>
               <Form.Group as={Col} >
                   <Form.Label className="my-1 mr-2" >
                        Kangas
                    </Form.Label>
                    <Form.Control
                        as="select"
                        name='fabrics'
                        className="my-1 mr-sm-2"
                        id="inlineFormCustomSelectPrefSurface"
                        onChange={event =>handleInputChangeSelect(index, event)}
                        custom 
                    >
                        <option>Tiputusvalikko</option>
                         {windows[index].fabrics.map(({name, price}, index) => (
                        <option key={index} value={[name,price]}>{name}, {price}€/m*2</option>
                    ))}
                        
                    </Form.Control>
                   </Form.Group>
               </Form.Row>

               <Form.Row>
               <Form.Group as={Col} >
                   <Form.Label className="my-1 mr-2" >
                        Mittaustapa
                    </Form.Label>
                    <Form.Control
                        as="select"
                        className="my-1 mr-sm-2"
                        id="inlineFormCustomSelectPrefSurface"
                        custom
                        name="measurement"
                        onChange={event =>handleInputChangeSelect(index, event)}
                    >
                        <option>Tiputusvalikko</option>
                         {windows[index].measurement.map((measurement, index) => (
                        <option key={index} value={[measurement.name]}>{measurement.name}</option>
                    ))}
                        
                    </Form.Control>
                   </Form.Group>
                   <Form.Group as={Col} >
                   <Form.Label className="my-1 mr-2" >
                        Kannakkeet
                    </Form.Label>
                    <Form.Control
                        as="select"
                        className="my-1 mr-sm-2"
                        id="inlineFormCustomSelectPrefSurface"
                        custom
                        name="brackets"
                        onChange={event =>handleInputChangeSelect(index, event)}
                    > 
                    <option>Tiputusvalikko</option>
                    {windows[index].brackets.map((brackets, index) => (
                        <option key={index} value={[brackets.name,brackets.price,brackets.vaiKanLev]}>{brackets.name}, {brackets.price}€, {brackets.vaiKanLev} cm</option>
                    ))}
                        
                    </Form.Control>
                   </Form.Group>
                   
               </Form.Row>
               <Form.Row>
                    
                   <Form.Group as={Col} >
                   <Form.Label>Leveys - cm</Form.Label>
                   <Form.Control name="width" type="number" placeholder="Leveys" value={windows[index].width} onChange={event =>handleInputChange(index, event)} />
                   </Form.Group>
   
                   <Form.Group as={Col} >
                   <Form.Label>Korkeus - cm</Form.Label>
                   <Form.Control name="height" type="number" placeholder="Pituus" value={windows[index].height} onChange={event =>handleInputChange(index, event)} />
                   </Form.Group>
               </Form.Row>
               <Form.Row>
                   <Form.Group as={Col} >
                   <Form.Label>Asennuskorkeus - cm</Form.Label>
                   <Form.Control name="installHeight" type="number" placeholder="Asennuskorkeus" value={windows[index].installHeight} onChange={event =>handleInputChange(index, event)}/>
                   </Form.Group>
                   <Form.Group as={Col} >
                   <Form.Label className="my-1 mr-2" >
                        Asennuspinta
                    </Form.Label>
                    <Form.Control
                        as="select"
                        className="my-1 mr-sm-2"
                        id="inlineFormCustomSelectPrefSurface"
                        name="installSurface"
                        custom
                        onChange={event =>handleInputChangeSelect(index, event)}
                    > 
                    <option>Tiputusvalikko</option>
                    {windows[index].installSurface.map(({name,price}, index) => (
                        <option key={index} value={[name,price]} >{name}, {price}€</option>
                    ))}
                        
                    </Form.Control>
                   </Form.Group>
               </Form.Row>

               <Form.Row>
                   <Form.Group as={Col} >
                   <Form.Label>Ketjun pituus - cm</Form.Label>
                   <Form.Control name="chainLength" type="number" placeholder="Ketjun pituus" value={windows[index].chainLength} onChange={event =>handleInputChange(index, event)}/>
                   </Form.Group>
                   <Form.Group as={Col} >
                   <Form.Label className="my-1 mr-2" >
                        Asennuskohta
                    </Form.Label>
                    <Form.Control
                        as="select"
                        className="my-1 mr-sm-2"
                        id="inlineFormCustomSelectPrefSurface"
                        name="installLocations"
                        custom
                        onChange={event =>handleInputChangeSelect(index, event)}
                    > 
                    <option>Tiputusvalikko</option>
                    {windows[index].installLocations.map(({name,price}, index) => (
                        <option key={index} value={[name,price]} >{name}</option>
                    ))}
                        
                    </Form.Control>
                   </Form.Group>
               </Form.Row>
               <Form.Row>
               <Form.Group as={Col} >
                   <Form.Label className="my-1 mr-2" >
                        Kätisyys
                    </Form.Label>
                    <Form.Control
                        as="select"
                        className="my-1 mr-sm-2"
                        id="inlineFormCustomSelectPrefSurface"
                        custom
                        name="hand"
                        onChange={event =>handleInputChangeSelect(index, event)}
                    >
                        <option>Tiputusvalikko</option>
                         {windows[index].hand.map((hand, index) => (
                        <option key={index} value={[hand.name,hand.price]}>{hand.name}</option>
                    ))}
                        
                    </Form.Control>
                   </Form.Group>
                   <Form.Group as={Col} >
                   <Form.Label className="my-1 mr-2" >
                        Kelautuvuus
                    </Form.Label>
                    <Form.Control
                        as="select"
                        className="my-1 mr-sm-2"
                        id="inlineFormCustomSelectPrefSurface"
                        custom
                        name="rewind"
                        onChange={event =>handleInputChangeSelect(index, event)}
                    > 
                    <option>Tiputusvalikko</option>
                    {windows[index].rewind.map((rewind, index) => (
                        <option key={index} value={[rewind.name,rewind.price]}>{rewind.name}</option>
                    ))}
                        
                    </Form.Control>
                   </Form.Group>
                   
               </Form.Row>
               <Form.Row>
               <Form.Group as={Col} >
                   <Form.Label className="my-1 mr-2" >
                        Mekanismi
                    </Form.Label>
                    <Form.Control
                        as="select"
                        className="my-1 mr-sm-2"
                        id="inlineFormCustomSelectPrefSurface"
                        custom
                        name="mechanism"
                        onChange={event =>handleInputChangeSelect(index, event)}
                    > 
                    <option>Tiputusvalikko</option>
                    {windows[index].mechanism.map((mekanismi, index) => (
                        <option key={index} value={[mekanismi.name,mekanismi.price,mekanismi.vaiKanLev]}>{mekanismi.name}, {mekanismi.price}€, {mekanismi.vaiKanLev} cm</option>
                    ))}
                        
                    </Form.Control>
                   </Form.Group><Form.Group as={Col} >
                   <Form.Label className="my-1 mr-2" >
                        Alalista
                    </Form.Label>
                    <Form.Control
                        as="select"
                        className="my-1 mr-sm-2"
                        id="inlineFormCustomSelectPrefSurface"
                        custom
                        name="lathColor"
                        onChange={event =>handleInputChangeSelect(index, event)}
                    >
                        <option>Tiputusvalikko</option>
                         {windows[index].lathColor.map((lathc, index) => (
                        <option key={index} value={[lathc.name,lathc.price]}>{lathc.name}, {lathc.price}€</option>
                    ))}
                        
                    </Form.Control>
                   </Form.Group>
               </Form.Row>
               <Form.Row>
                </Form.Row>
                <Form.Row>
                <Form.Group as={Col} >
                   <Form.Label>Vapaa kommentti</Form.Label>
                   <Form.Control name="freeComment" type="text" as="textarea" placeholder="Vapaa kommentti" value={windows[index].freeComment} onChange={event =>handleInputChange(index, event)}/>
                   </Form.Group>
                </Form.Row>
                <Form.Row>
                <Form.Group as={Col} >
                   <Form.Label>Ikkunoita - kpl</Form.Label>
                   <Form.Control name="windowAmount" type="number" placeholder="Ikkunoiden kappalemäärä" value={windows[index].windowAmount} onChange={event =>handleInputChange(index, event)} />
                   </Form.Group>
                </Form.Row>
               <Form.Row>
               <Form.Label className="my-1 mr-2" >
                        Lisätarvikkeet
                    </Form.Label>
                    </Form.Row>

                    <Form.Row>
                
               {
               windows[index].extras.map((extra, i) => (
                    
                    <Form.Check className="ml-1 mr-1"
                        key={uuidv4()}
                        name="extras"
                        custom
                        type='checkbox'
                        id={uuidv4()}
                        checked={extra.isSelected}
                        label={`${extra.name} - ${extra.price}€`}
                        value={[extra.name,extra.price]}
                        onChange={event =>handleInputChangeSelect(index, event, i)}
                    />
                    
                ))}
                
                </Form.Row>

                <Form.Row>
                <Form.Group>
                        <div className="mt-3">
                       <h5>Hinta ikkunalle kokonaisuudessaan: {windows[index].windowprice.toFixed(2)}€</h5>
                       </div>
                   </Form.Group>
                   </Form.Row>
                    <Form.Row >
                        <Button variant="danger" onClick={() => handleDeleteWindow(index)} className="mlButton ml-1 mr-1 mt-1 mb-1">Poista ikkuna <i className="fas fa-trash fa-lg"></i></Button>
                    </Form.Row>
   
   
               
               </Form.Group>
               </Form.Group>    
            ))}

            <Button variant="success" onClick={() => handleNewWindow()}>Uusi ikkuna  <i className="fas fa-plus-square ml-1 fa-lg"></i></Button>
            <Form.Row className="mt-3">
            <Form.Group as={Col} >
                   <Form.Label>Asennuksen kesto - tunti</Form.Label>
                   <Form.Control name="installTime" type="number" placeholder="Asennuksen kesto" value={orderTime} onChange={event =>handleInputChangeTime(event)}/>
            </Form.Group>
            <Form.Group as={Col} >
                   <Form.Label className="my-1 mr-2" >
                        Asentajat
                    </Form.Label>
                    
                    <Form.Control
                        as="select"
                        className="my-1 mr-sm-2"
                        id="inlineFormCustomSelectPrefInstaller"
                        custom
                        name="installers"
                        onChange={event =>handleInputChangeSelectLabor(event)}
                        value={installers.installers}
                    > 
                    <option>Tiputusvalikko</option>
                    {installers.map((installer, index) => (
                        <option key={uuidv4()} value={[installer.name,installer.price]}>{installer.name} {installer.name === 1 ? 'asentaja' : 'asentajaa'}, {installer.price}€</option>
                    ))}
                        
                    </Form.Control>
            </Form.Group>
            </Form.Row>


            <h5 className='mt-5'>Materiaalit: {overallPrice.toFixed(2)}€</h5>
            <h4 className='mt-3'>Materiaalit + kopiot: {overallPriceWithCopies.toFixed(2)}€</h4>
            <h4 className='mt-5'>Työ: {orderLaborAmount.toFixed(2)}€</h4>
            <h5 className='mt-5'>Tilaus: {overallPriceAll.toFixed(2)}€</h5>
            <h4 className='mt-3'>Tilaus sis kopiot: {overallPriceAllWithCopies.toFixed(2)}€</h4>
            
            
           

            <Form.Group className="mt-5" id="formGridCheckbox">
                <Form.Check type="checkbox" label="Varmistus" checked={varmistusPainike}  onChange={event => handleVarmistus(event)}/>
            </Form.Group>

            <Button variant="primary" disabled={!varmistusPainike} className="mt-4" onClick={() => handleOrderSend()}>
                Lähetä
            </Button>
            
            </Form>
            
            }
            
            {notEnteredField.length > 0 && <h5 style={{color: 'red'}} className="mt-2">Täyttämättä: {notEnteredField}</h5>}
            {orderSaveSuccess && <div><h3 className="mt-4">Lähetys onnistui</h3><Button variant="success" className="mt-4" onClick={() => history.push('/orders')}>Siirry tilauksiin</Button></div>}
            
            
             
        </React.Fragment>
    )
}

export default NewOrder
