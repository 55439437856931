import React, {useState, useEffect, useContext } from 'react'
import {Form, Col, Button, Card, Row} from 'react-bootstrap'
import axios from 'axios'


import Loader from '../components/Loader'
import {AuthContext} from '../context/authContext'


const UserEdit = () => {
    const auth = useContext(AuthContext);

    const [isLoading, setIsLoading] = useState(true)
    const [users, setUsers] = useState([])
    



    const [newUserName, setNewUserName] = useState('')
    const [newUserPassword, setNewUserPassword] = useState('')
    const [newUserIsAdmin, setNewUserIsAdmin] = useState(false)


    const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }
    

    const newUserNameHandler = (event) => {
        setNewUserName(event.target.value)
    }
    const newUserPasswordHandler = (event) => {
        setNewUserPassword(event.target.value)
    }

    const newUserAdminHandler = (event) => {
        
        setNewUserIsAdmin(event.target.checked)
        
    }

    const handleDeleteUser = async (id) => {
        await axios({
            url: `/api/users/deluser`,
            method:'delete',
            data : { removeId: id },
            headers:{Authorization: `Bearer ${auth.token}`}
        })

        let updatedUsers = users.filter(user => user._id !== id)
        setUsers(updatedUsers)
    }

    const handleNewUser = async () => {
        
        const userRes = await axios.post('/api/users/newuser',{ data: {name: newUserName, password:newUserPassword, isAdmin:newUserIsAdmin}}, config)
            
            let newUsr = userRes.data
            let newUsrs = [...users]
            newUsrs.push(newUsr)
            setUsers(newUsrs)

    }
    
    
 


    


      useEffect(() => {
        const fetchProducts = async () => {
                
            const usersData = await axios.get('/api/users/', config)
            
               
            setUsers(usersData.data.updatedUsers)
            
            setIsLoading(false);

            }
    
            fetchProducts()
            // eslint-disable-next-line
        }, [])


    return (
        <div>
            {isLoading ? <Loader/> :
            <Form.Group>
                <h2>Uusi käyttäjä</h2>

                    <Form.Row >
                    <Form.Group as={Col} >
                    <Form.Label>Uusi käyttäjä</Form.Label>
                    <Form.Control name="name" type="text" placeholder="Käyttäjänimi" value={newUserName.value} onChange={event => newUserNameHandler(event)} />
                    </Form.Group>
    
                    <Form.Group as={Col} >
                    <Form.Label>Salasana</Form.Label>
                    <Form.Control name="password" type="text" placeholder="Salasana" value={newUserPassword.value} onChange={event => newUserPasswordHandler(event)} />
                    </Form.Group>
                    
                    <Button variant="success" className="ml-2 mt-4 mb-3" onClick={() => handleNewUser()}>Luo käyttäjä</Button>
                    </Form.Row>
                    <Form.Row className="mb-5 ml-1"><Form.Check inline label="Uudella käyttäjällä on täydet oikeudet" type="checkbox"  className="mr-2" checked={newUserIsAdmin} onChange={event => newUserAdminHandler(event)}/></Form.Row>

                    <h2>Käyttäjät</h2>
                {users.map( (user, index) =>  
                    
                    <Card key={user._id}>
                    <Form.Row >
                    <Form.Group as={Col} style={{width: "40vw"}}>
                    <Form.Label>Käyttäjä</Form.Label>
                    <Form.Control name="room" type="text" placeholder="Käyttäjänimi" value={user.name}  readOnly />
                    </Form.Group>
                    </Form.Row>
                    <Row className="justify-content-md-center">
                    <Col sm={8}>{user.isAdmin ? <React.Fragment><p className=" mr-2 ml-2 mt-1" >Täydet oikeudet <i className="fas fa-users-cog 5x  mr-5" style={{color: "green"}}></i></p> </React.Fragment> :
                    <React.Fragment><p className=" mr-2 ml-2 mt-1"  >Rajoitetut oikeudet <i className="fas fa-users-cog 5x  mr-5" style={{color: "red"}}></i></p> </React.Fragment>}
                    </Col>
                    <Col sm={4}><Button variant="danger" className="ml-2  mb-3" id={user._id} onClick={() => handleDeleteUser(user._id)}>Poista käyttäjä</Button></Col>
                    </Row>
                    </Card>
                    )}
            </Form.Group>
            }
        </div>
    )
}

export default UserEdit
