import React, {useState, useContext, useEffect} from 'react'
import { Container, Row, Col, Button} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Loader from '../components/Loader'
import {AuthContext} from '../context/authContext'

    

const AllOrders = () => {

    const auth = useContext(AuthContext);

    

    const [orders, setOrders] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }

    useEffect(() => {
        const fetchOrders = async () => {
            const ordersData = await axios.get('/api/orders/', config) 
            
            if(auth.isAdmin){
            
            setOrders(ordersData.data.reverse())
            setTimeout(setIsLoading(false), 1000);
            } else {
                let newArr = ordersData.data.filter(order => order.sellerId === auth.userId)
                
                setOrders(newArr.reverse())
                setTimeout(setIsLoading(false), 1000);
                
            }
            
            }

            fetchOrders()
            // eslint-disable-next-line
    }, [])



        // Handler

        const handleOrderisReady = async (id, idx) => {

            const orderReadyRes = await axios.put('/api/orders/orderisready',{ data: {orderId: id}}, config)
            if(orderReadyRes.statusText === "OK"){
                const updatedOrderStatuses = [...orders]
            updatedOrderStatuses[idx].isReady = true
            setOrders(updatedOrderStatuses)
            }
            
        }
        
        const handleDeleteOrder = async ( id ) => {
            await axios({
                url: `/api/orders/deleterorder`,
                method:'delete',
                data : { removeId: id },
                headers:{Authorization: `Bearer ${auth.token}`}
              })
            
            const updatedOrders = orders.filter(order => order._id !== id)
            setOrders(updatedOrders)
        }







    return (
            
            
            <Container>
                {isLoading ? <Loader /> : 
                <React.Fragment>
                <Row className="mb-5">
                <Col xs={6} md={auth.isAdmin ? 5 : 8}>
                  <h4>Tilaus</h4>
                </Col>
                <Col xs={4} md={3}>
                  <h4>Myyjä</h4>
                </Col>
                {auth.isAdmin ?
                <Col xs={6} md={2} className="mt-2">
                    <h5>Tila</h5>
                </Col> : ''
                }
                {auth.isAdmin ?
                <Col xs={4} md={2} className="mt-2">
                    <h5>Poisto</h5>
                </Col> : ''
                }
                </Row>
                    {orders.map((order, index) => 
                        
                        <Row  className="mt-2 mb-2 pt-2 pb-2" style={{borderStyle: "solid", borderColor: "black"}} key={order._id}>
                            
                            <Col xs={6} md={auth.isAdmin ? 5 : 8} className="mt-4">
                            <Link to={`/orders/${order._id}`} ><h5>{order.orderName} <i className="fas fa-file-alt fa-lg"></i></h5></Link>
                            </Col>
                            
                            <Col xs={4} md={3} className="mt-4">
                            <h5>{order.sellerName}</h5>
                            </Col>
                            {auth.isAdmin ?
                            <Col xs={6} md={2} >
                            <Button disabled={order.isReady} className="mt-2 mb-2" variant={!order.isReady ? "warning" : "success"} onClick={() => handleOrderisReady(order._id, index)}>{!order.isReady ? "Kesken" : "Valmis"}</Button>
                            </Col>: ''
                            }
                            {auth.isAdmin ?
                            <Col xs={4} md={2} >
                            <Button  variant="danger" onClick={() => handleDeleteOrder(order._id)} className="mt-2 mb-2">Poista</Button>
                            </Col>: ''
                            }
                        </Row>
                        
                         
                    )}
                    
                </React.Fragment>
                }
            </Container>
            
        
    )
}

export default AllOrders





