const mittaustavat = [
    {
        name: 'Piikki piikki',
        
    },
    {
        name: 'Kankaan leveys',
        
    },
    
]

export default mittaustavat