import React, {useContext} from 'react'
import { Container, Navbar, Nav, Button} from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import {AuthContext} from '../context/authContext'
import { useHistory } from 'react-router-dom';



    


const Header = () => {

    const auth = useContext(AuthContext);
    const history = useHistory()
    

    const logOutHandler = () => {
        auth.logout()
        history.push('/')
    }

    let links
    if(auth.token && !auth.isAdmin){
        links = (
        <React.Fragment>
        <LinkContainer to='/order' style={{border: "solid"}} className="pl-2 pr-2">
        <Nav.Link >Uusi tilaus</Nav.Link>
        </LinkContainer>
        <LinkContainer to='/orders' style={{border: "solid"}} className="pl-2 pr-2">
        <Nav.Link >Omat Tilaukset</Nav.Link> 
        </LinkContainer>
        <Button variant="danger" className="ml-4 mr-4 pushLogoutMobile" size="sm" onClick={() => logOutHandler()}>Kirjaudu ulos</Button>
        </React.Fragment>
        );
    } else if(auth.token && auth.isAdmin){
        links = (
            <React.Fragment>
                    <LinkContainer to='/order' style={{border: "solid"}} className="pl-2 pr-2">
                    <Nav.Link >Uusi tilaus</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to='/orders' style={{border: "solid"}} className="pl-2 pr-2">
                    <Nav.Link >Tilaukset</Nav.Link> 
                    </LinkContainer>
                    <LinkContainer to='/products' style={{border: "solid"}} className="pl-2 pr-2">
                    <Nav.Link >Tuotteet</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to='/users' style={{border: "solid"}} className="pl-2 pr-2">
                    <Nav.Link >Käyttäjät</Nav.Link>
                    </LinkContainer>
                    <Button variant="danger" className="ml-4 mr-4 pushLogoutMobile" size="sm" onClick={() => logOutHandler()}>Kirjaudu ulos</Button>
            </React.Fragment>
            );
    } else {<React.Fragment />}



    

    return (
        <header>
            <Navbar bg="light" expand="lg">
                <Container>
                    <LinkContainer to='/'>
                        
                    <Navbar.Brand > Mittaustyökalu</Navbar.Brand>
                    
                    
                    </LinkContainer>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto">
                    
                    {links}
                       
                </Nav>
                
                </Navbar.Collapse>
                
                </Container>
            </Navbar>
        </header>
    )
}

export default Header
