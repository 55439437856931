import React, {useState, useEffect, useContext} from 'react'
import { Container, Card, Button} from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import Loader from '../components/Loader'
import {AuthContext} from '../context/authContext'


    

const SingleOrder = () => {
    
    const [isLoading, setIsLoading] = useState(true)
    const [printState, setPrintState] = useState(false)
    const [pageOrder, setPageOrder] = useState({})
    
    const [pageOrderWindows, setPageOrderWindows] = useState({})
    const auth = useContext(AuthContext);

    const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }



    let params = useParams();

    useEffect(() => {
        const fetchOrders = async () => {
            const ordersData = await axios.get(`/api/orders/${params.orderid}`,config) 
            
            
            setPageOrder(ordersData.data[0])
            setPageOrderWindows(ordersData.data[0].windows)
            
            setIsLoading(false)
            
            
            }

            fetchOrders()
            // eslint-disable-next-line
    }, [])

    const handlePrintState = () => {
        setPrintState(prevState => !prevState);
    }
    
    


    
    return (
        <div>
            {isLoading ? <Loader /> :
            <Container>
                 <Button className="mt-2 mb-5 no-print" variant={!printState ? "primary" : "secondary"} onClick={() => handlePrintState()}>{!printState ? "Vaihda asiakkaan versioon" : "Vaihda tuotanto versioon"}</Button>
            <h1 className={!printState ? "no-print" : ""}>Tilaaja: {pageOrder.orderName}</h1>
            <h2 className={!printState ? "no-print" : ""}>Myyjä: {pageOrder.sellerName}</h2>
            <h2 className={!printState ? "no-print" : ""}>Ikkunoiden määrä: {pageOrder.windows.length}</h2>
            <h2 className={!printState ? "no-print" : ""}>Asennuksen hinta: {pageOrder.orderPriceLabor.toFixed(2)}€</h2>
            <h2 className={!printState ? "no-print" : ""}>Materiaalien hinta: {pageOrder.orderPrice.toFixed(2)}€</h2>
            <h2 className={!printState ? "no-print" : ""}>Tilauksen hinta: {pageOrder.orderPriceAll.toFixed(2)}€</h2>
            <h3 className={!printState ? "no-print" : ""}>Asentajat: {pageOrder.installers}</h3>
            <h3 className={!printState ? "no-print" : ""}>Asennusaika: {pageOrder.orderTime} {pageOrder.orderTime > 1 ? 'tuntia' : 'tunti'}</h3>
            
            {pageOrderWindows.map((window, index) => (
                
                <Card style={{padding: "1rem", margin: "1rem", pageBreakBefore: 'always'}} key={window._id}>
                <h1>Tilaaja: {pageOrder.orderName}</h1>
                <h2 className="mt-1 mb-1">Huone: {window.room}</h2>
                <h3 className="pt-1 pb-1 mb-4">Tunniste: {window.identifier}</h3>
                <h3 className="pt-1 pb-1 mb-4">Ikkuna: {index+1} / {pageOrderWindows.length}</h3>

                <h4 className="pt-1 pb-1">Mittaustapa: {window.measurement}</h4>
                <h4 className="pt-1 pb-1">Putken mitta: {window.pipeLength} cm</h4>
                <h4 className="pt-1 pb-1">Kangas: {window.fabrics}</h4>
                <h4 className="pt-1 pb-1">Kankaan leveys: {window.fabricWidth} cm</h4>
                <h4 className="pt-1 pb-1">Kankaan korkeus: {window.height} cm</h4>
                <h4 className="pt-1 pb-1">Ketjun pituus: {window.chainLength} cm</h4>
                <h4 className="pt-1 pb-1">Alalistan väri: {window.lathColor}</h4>
                <h4 className="pt-1 pb-1">Asennuskorkeus: {window.installHeight} cm</h4>
                <h4 className="pt-1 pb-1">Asennuspinta: {window.installSurface}</h4>
                <h4 className="pt-1 pb-1">Asennuskohta: {window.installLocations}</h4>
                <h4 className="pt-1 pb-1">Mekanismi: {window.mechanism}</h4>
                <h4 className="pt-1 pb-1">Kannake: {window.brackets}</h4>
                <h4 className="pt-1 pb-1">Kelautuvuus: {window.rewind}</h4>
                <h4 className="pt-1 pb-1">Kätisyys: {window.hand}</h4>
                <h4 className="pt-1 pb-1 mb-2">Lisätarvikkeet: {pageOrderWindows[index].extras.map(extra => (extra +', '))}</h4>
                <h4 className="pt-1 pb-1">Vapaa kommentti: {window.freeComment}</h4>
                <h4 className={!printState ? "no-print pt-1 pb-1" : "pt-1 pb-1"}>Ikkunan hinta: {window.windowprice.toFixed(2)}€</h4>
                
                </Card>
            ))}
            
            
            </Container>
            }
        </div>
    )
}

export default SingleOrder
