const rewinding = [
    {
        _id: '1',
        name: 'Normaalisti kelautuva',
        price: 0
    },
    {
        _id: '2',
        name: 'Edestä kelautuva',
        price: 0
    },
    
]

export default rewinding