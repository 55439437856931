import React, { useState, useContext} from 'react'
import axios from 'axios'
import { Button, Container, Form,  Nav} from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import {AuthContext} from '../context/authContext'
import './mobileStyleHelper.css'


const Login = () => {
    const auth = useContext(AuthContext);
    const [loginName, setLoginName] = useState('')
    const [loginPassword, setLoginPassword] = useState('')
    const [loginMode, setLoginMode] = useState(false)
    

    

    const loginNameHandler = (event) => {
        setLoginName(event.target.value)
    }
    const loginPasswordHandler = (event) => {
        setLoginPassword(event.target.value)
    }
    const loginHandler = async () => {
        
        const loginres = await axios.post('/api/users/login',{ name: loginName, password: loginPassword})
        
        auth.login(loginres.data._id, loginres.data.token, loginres.data.name, loginres.data.isAdmin)
        if(loginres){
            setLoginMode(false)
            setLoginName('')
            setLoginPassword('')
        }

    }
    const loginModeHandler = () => {
        setLoginMode(true)
    }
    


    return (
        <Container>

                
                {!loginMode && !auth.token ? <div style={{textAlign: 'center'}} className="mt-5 mb-5">
                    <a className="sivulinkki" href="https://www.gardin.fi/">Katso tuotteita sivuilta Gardin.fi</a>
                </div> : ''}
                {!auth.token && loginMode ? 
                <Form>
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Käyttäjänimi</Form.Label>
                    <Form.Control type="name" placeholder="Käyttäjänimi" value={loginName} onChange={event => loginNameHandler(event)}/>
                    
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                    <Form.Label>Salasana</Form.Label>
                    <Form.Control type="password" placeholder="Salasana"  value={loginPassword} onChange={event => loginPasswordHandler(event)}/>
                </Form.Group>
                
                <Button variant="primary" onClick={() => loginHandler()}>
                    Kirjaudu sisään
                </Button >
                </Form>: ( auth.token && !loginMode ?
                    <LinkContainer to='/order'  style={{   margin: 'auto',display: 'block',textAlign: 'center'}} className="mt-5">
                    <Nav.Link  ><Button  variant="success" size="lg">Tee uusi tilaus</Button></Nav.Link>
                    </LinkContainer> : <div style={{textAlign: 'center'}} className="mt-5"><Button onClick={() => loginModeHandler() }>Siiry sisäänkirjautumiseen</Button></div>)
                }    
                
            
        </Container>
            
                
    )
}

export default Login
